import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO, { seoDataFormat } from "../../shared/seo"

const ResidentialLendingSEO = () => {
  const {
    sanityResidentialLendingPage: { seo },
  } = useStaticQuery(graphql`
    query {
      sanityResidentialLendingPage {
        seo {
          pageTitle
          pageDescription
          pageKeyWords
          ogImage {
            asset {
              url
            }
          }
          slug {
            current
          }
        }
      }
    }
  `)

  const seoData = seoDataFormat(seo)

  return <SEO {...seoData} />
}

export default ResidentialLendingSEO
