import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Tabs } from "antd"
import PartnersCarousel from "./partnersCarousel"
import MyLink from "../sub/myLink"
import { SanityPartnersLists } from "../../../../graphql-types"

import PartnersSection from "./customPartnersStyle"

const { TabPane } = Tabs

interface Props {
  data: SanityPartnersLists
  backgroundColor?: boolean
  hasBottomLink?: boolean | false
  padding?: string
}

const CustomPartners = ({
  data,
  backgroundColor,
  hasBottomLink,
  padding,
}: Props) => {
  return (
    <PartnersSection padding={padding} backgroundColor={backgroundColor}>
      <div className="container">
        <h3 className="title">{data.title}</h3>
        <Tabs defaultActiveKey="1" centered>
          {data.partnersLists.map((partnersList, index) => (
            <TabPane tab={partnersList.categoryTitle} key={index + 1}>
              <PartnersCarousel
                disableAutoplay
                partners={partnersList.partners}
              />
            </TabPane>
          ))}
        </Tabs>
        {hasBottomLink && (
          <MyLink className="bottom-link" url={data.link.url}>
            {data.link.linkText}
          </MyLink>
        )}
      </div>
    </PartnersSection>
  )
}

export default CustomPartners
