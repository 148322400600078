import styled from "styled-components"

export default styled.section<{
  backgroundColor: boolean
  padding?: string
}>`
  padding-top: 6rem;
  padding-bottom: 8rem;
  ${({ padding }) => padding && `padding: ${padding};`}
  ${props => props.backgroundColor && "background-color: rgba(85,30,43,0.05);"}

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
  }

  .container {
    max-width: 1200px;

    h3.title {
      text-align: center;
      color: var(--red);
      font-size: 2.4rem;
      margin-bottom: 3rem;
    }

    .partners-carousel {
      padding: 0 8rem;

      @media only screen and (max-width: 768px) {
        padding: 0 5rem;
      }

      @media only screen and (max-width: 768px) {
        padding: 0 2rem;
      }

      .slick-arrow {
        fill: var(--red);
        opacity: 0.3;

        &:hover {
          opacity: 0.6;
        }

        svg {
          width: 100%;
          height: 100%;
          color: var(--red);
        }
      }

      .gatsby-image-wrapper {
        height: 10rem;

        img {
          padding: 15px;
          object-fit: contain !important;

          @media only screen and (max-width: 600px) {
            padding: 15px;
          }
        }
      }
    }

    a.bottom-link {
      display: block;
      width: fit-content;
      text-decoration: underline;
      margin: 0 auto;
      margin-top: 2rem;
      font-family: medium;

      :hover {
        opacity: 0.7;
        color: var(--red);
      }
    }
  }
`
