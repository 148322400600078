import React from "react";
import { graphql, PageProps } from "gatsby";

import ResidentialLendingSEO from "../../components/services/residential-lending/residentialLendingSEO";
import TestimonialsCarousel from "../../components/shared/testimonials";
import BlogsSection from "../../components/shared/blogsSection";
import { Query } from "@graphql-types";
import { TextButtons, Ctas, ContentTabs, Header, IframeEmbed } from "@global";
import TabsLink from "@components/shared/sub/tabsLink";
import CustomPartners from "@components/shared/partners/customPartners";

interface Props extends PageProps {
  data: Query;
}

const ResidentialLending = ({ data }: Props) => {
  if (data.sanityResidentialLendingPage == null) return null;

  const {
    textButtons,
    ctas,
    contentTabs,
    title,
    header,
    lendingPartners,
    iframeEmbed,
    topTextButtons,
  } = data.sanityResidentialLendingPage;
  const shared = data.sanitySharedSection;

  return (
    <div className="subpage-container">
      <ResidentialLendingSEO />
      <Header title={title} headerData={header} />

      {shared && shared.servicesTabs && (
        <TabsLink
          tabs={shared.servicesTabs}
          pageColor={header?.headerColor}
          slug="/services/home-loans/"
        />
      )}
      {topTextButtons && <TextButtons data={topTextButtons} />}
      {ctas && <Ctas data={ctas} />}
      {contentTabs && <ContentTabs data={contentTabs} />}
      {iframeEmbed && <IframeEmbed data={iframeEmbed} />}
      {textButtons && <TextButtons data={textButtons} />}
      {lendingPartners &&
        lendingPartners?.partnersLists &&
        lendingPartners.partnersLists?.length > 0 && <CustomPartners data={lendingPartners} />}
      <TestimonialsCarousel color="red" />
      <BlogsSection title="Related Blogs" category="Home Loans" />
    </div>
  );
};

export default ResidentialLending;

export const query = graphql`
  query {
    sanityResidentialLendingPage {
      title
      header {
        ...sanityHeader
      }
      textButtons {
        ...sanityTextButton
      }
      topTextButtons {
        ...sanityTextButton
      }
      iframeEmbed {
        ...sanityIframeEmbed
      }
      ctas {
        ...sanityCtas
      }
      contentTabs {
        ...sanityContentTab
      }
      lendingPartners {
        title
        partnersLists {
          _key
          categoryTitle
          partners {
            _key
            partnerName
            partnerLogo {
              ...sanityFlightImage
            }
            partnerLink
          }
        }
        link {
          linkText
          url
        }
      }
    }
    sanitySharedSection {
      servicesTabs {
        serviceTab {
          _key
          linkText
          url
        }
      }
    }
  }
`;
